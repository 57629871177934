export const INDEBTEDNESS_RATE = 0.35
export const INPUT_DEBOUNCE_RATE = 250
export const DURATION_MIN = 10
export const DURATION_MAX = 25
export const DEFAULT_INCOME = 4000
export const DEFAULT_RATE = 2.16
export const REDIRECT = 'https://app.pretto.fr'
export const REDIRECT_PURCHASE = 'https://app.pretto.fr/project/purchase/introduction'
export const REDIRECT_CAPACITY = 'https://app.pretto.fr/project/capacity/introduction'
export const NOTARY_FEES_RATE = 0.08
export const GUARANTY_FEES_RATE = 0.01
