import { g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'

import styled from 'styled-components'

interface ButtonBaseProps {
  children: React.ReactNode
  onClick: () => void
  role?: string
  href?: string
  label?: string
}

export const ButtonBase: React.FC<ButtonBaseProps> = ({ children, onClick, role, href, label, ...props }) => {
  return (
    <Container {...props}>
      <Button onClick={onClick} role={role} data-href={href} aria-label={label}>
        {children}
      </Button>
    </Container>
  )
}

const BORDER_HEIGHT = 6

const Container = styled.div`
  position: relative;
  padding-top: ${BORDER_HEIGHT}px;
  border-radius: ${g(1)};

  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: ${BORDER_HEIGHT}px;
    z-index: 0;
    border-radius: inherit;
    background-color: ${({ theme }) => theme.colors.neutral1};
    content: '';
  }

  &:has(:focus-visible):has(:focus-within) {
    outline: 1px dashed ${({ theme }) => theme.colors.accent1};
  }
`
const Button = styled.button`
  ${typo.button18};
  font-weight: 900;
  border: 2px solid ${({ theme }) => theme.colors.neutral1};
  border-radius: inherit;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.neutral1};
  cursor: pointer;
  position: relative;
  z-index: 1;
  transform: translateY(-${BORDER_HEIGHT}px);
  transition: all 150ms ease-in-out;
  width: 100%;
  height: 100%;

  &:hover {
    transform: translateY(-${BORDER_HEIGHT / 2}px);
  }

  &:focus {
    outline: 0;
  }
`
