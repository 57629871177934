import { funcToItem } from '@pretto/bricks/core/utility/formatters'

import {
  contribution,
  duration,
  income,
  payment,
  rate,
} from '@pretto/website/src/templates/simulateurPretImmobilier/config/capacityInputs'
import { FieldConfig } from '@pretto/website/src/templates/simulateurPretImmobilier/containers/capacity'
import { FieldProps } from '@pretto/website/src/templates/simulateurPretImmobilier/views/SimulateurPretImmobilier/components/Field/Field'

import isNil from 'lodash/isNil'

const capacityInputs = [income, payment, contribution, duration, rate]

export const getCapacityInputsProps = (field: FieldConfig, marketRate: number) =>
  capacityInputs.map(({ slug, ...config }, index) => {
    const { value, onChange, state, valueSlider, onChangeSlider } = field[slug]
    const { sliderProps, textFieldProps } = config

    const fieldProps: FieldProps = {
      ...config,
      description: funcToItem(config.description, { marketRate }),
      isMain: index === 0,
      textFieldProps: {
        ...textFieldProps,
        value,
        onChange,
        state,
      },
      sliderProps:
        sliderProps && onChangeSlider && !isNil(valueSlider)
          ? {
              ...sliderProps,
              value: valueSlider,
              onChange: onChangeSlider,
            }
          : undefined,
    }

    return fieldProps
  })
