export enum TrackingKeys {
  CalculatorCTAClicked = 'CALCULATOR_CTA_CLICKED',
  CapacityClicked = 'CALCULATOR_CAPACITY_CLICKED',
  CapacityContributionChanged = 'CALCULATOR_CAPACITY_CONTRIBUTION_INPUT_CHANGED',
  CapacityDurationInputChanged = 'CALCULATOR_CAPACITY_DURATION_INPUT_CHANGED',
  CapacityDurationSliderChanged = 'CALCULATOR_CAPACITY_DURATION_SLIDER_CHANGED',
  CapacityIncomeChanged = 'CALCULATOR_CAPACITY_INCOME_INPUT_CHANGED',
  CapacityPaymentChanged = 'CALCULATOR_CAPACITY_PAYMENT_INPUT_CHANGED',
  CapacityRateChanged = 'CALCULATOR_CAPACITY_RATE_INPUT_CHANGED',
  PurchaseClicked = 'CALCULATOR_PURCHASE_CLICKED',
  PurchaseContributionChanged = 'CALCULATOR_PURCHASE_CONTRIBUTION_INPUT_CHANGED',
  PurchaseDurationInputChanged = 'CALCULATOR_PURCHASE_DURATION_INPUT_CHANGED',
  PurchaseDurationSliderChanged = 'CALCULATOR_PURCHASE_DURATION_SLIDER_CHANGED',
  PurchaseIncomeChanged = 'CALCULATOR_PURCHASE_INCOME_INPUT_CHANGED',
  PurchasePropertyPriceChanged = 'CALCULATOR_PURCHASE_PROPERTY_PRICE_INPUT_CHANGED',
  PurchaseRateChanged = 'CALCULATOR_PURCHASE_RATE_INPUT_CHANGED',
}
