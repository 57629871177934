import { boxShadow } from '@pretto/bricks/components/helpers'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import * as typo from '@pretto/bricks/core/typography'
import { formatNumber } from '@pretto/bricks/core/utility/formatters'

import { Button } from '@pretto/website/src/templates/simulateurPretImmobilier/views/SimulateurPretImmobilier/components/Button/Button'
import { Ticks } from '@pretto/website/src/templates/simulateurPretImmobilier/views/SimulateurPretImmobilier/components/Ticks/Ticks'

import { ComponentPropsWithoutRef, ElementType, forwardRef, Ref } from 'react'
import styled from 'styled-components'

export type ButtonClickArgs = { buttonType: 'main' | 'sticky' | 'profile' }
export type ButtonClick = ({ buttonType }: ButtonClickArgs) => void

interface ResultProps {
  title: string
  result: number
  buttonLabel: string
  onClickButton: ButtonClick
  redirect: string
}

export const Result = forwardRef(
  <T extends ElementType = 'div'>(
    {
      children,
      title,
      result,
      buttonLabel,
      onClickButton,
      redirect,
      ...props
    }: ResultProps & ComponentPropsWithoutRef<T>,
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <Main ref={ref} {...props}>
        <Head>
          <Title>{title}</Title>
          <Highlight>{formatNumber(result, { suffix: '€' })}</Highlight>
        </Head>

        <Divider />

        <MainContainer>
          <Content>{children}</Content>

          <ButtonComponent
            onClick={onClickButton.bind(null, { buttonType: 'main' })}
            role="link"
            href={redirect}
            label="App Pretto"
          >
            {buttonLabel}
          </ButtonComponent>

          <TicksComponent />
        </MainContainer>
      </Main>
    )
  }
)

Result.displayName = 'Result'

const Main = styled.div`
  ${grid()};
  background-color: ${({ theme }) => theme.colors.primary2};
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  padding-top: ${g(2)};
  padding-bottom: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-bottom: ${g(4)};
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${boxShadow('card')};
    display: block;
    border-radius: ${g(1)};
    margin-bottom: ${g(-4)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding-top: ${g(3)};
    flex: 0 0 50%;
    min-width: ${g(52)};
    transform: translateX(${g(3)});
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-left: ${g(4)};
    flex-basis: ${g(65)};
  }
`
const Divider = styled.div`
  ${column([1, -1])};
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary1};

  @media screen and (min-width: ${breakpoints.laptop}) {
    display: none;
  }
`
const Head = styled.div`
  ${column([2, -2])};
  padding-bottom: ${g(1)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding-bottom: 0;
  }
`
const MainContainer = styled.div`
  ${column([2, -2])};
`
const Title = styled.h2`
  ${typo.headline16};
`
const Content = styled.div`
  margin-top: ${g(2)};
  padding: ${g(1)} ${g(2)} ${g(2)};
  text-align: initial;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-left: ${g(10)};
    padding-right: ${g(10)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-top: 0;
    padding-top: 0;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-left: ${g(15)};
    padding-right: ${g(15)};
  }
`
const Highlight = styled.div`
  ${typo.numbers32};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${typo.numbers48};
    margin-top: ${g(1)};
  }
`
const ButtonComponent = styled(Button)`
  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-left: ${g(10)};
    margin-right: ${g(10)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-left: ${g(5)};
    margin-right: ${g(5)};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-left: ${g(10)};
    margin-right: ${g(10)};
  }
`
const TicksComponent = styled(Ticks)`
  margin-top: ${g(1)};
`
