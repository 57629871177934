import { roundNumber } from '@pretto/bricks/core/utility/formatters'

import { TextFieldProps } from '@pretto/zen/atoms/textFields/TextField/TextField'

import {
  DEFAULT_RATE,
  DURATION_MAX,
  DURATION_MIN,
  INPUT_DEBOUNCE_RATE,
  REDIRECT_PURCHASE,
} from '@pretto/website/src/templates/simulateurPretImmobilier/config/static'
import { TrackingKeys } from '@pretto/website/src/templates/simulateurPretImmobilier/config/tracking'
import { MiniSimulatorContext } from '@pretto/website/src/templates/simulateurPretImmobilier/contexts/MiniSimulatorContext/MiniSimulatorContext'
import * as calc from '@pretto/website/src/templates/simulateurPretImmobilier/lib/calc'
import { getRateFromSimulator } from '@pretto/website/src/templates/simulateurPretImmobilier/lib/helpers'
import { getPurchaseInputsProps } from '@pretto/website/src/templates/simulateurPretImmobilier/lib/purchaseInputProps'
import { Purchase } from '@pretto/website/src/templates/simulateurPretImmobilier/views/SimulateurPretImmobilier/components/Purchase/Purchase'
import { ButtonClickArgs } from '@pretto/website/src/templates/simulateurPretImmobilier/views/SimulateurPretImmobilier/components/Result/Result'
import { useTracking } from '@pretto/website/src/utilities'

import { useContext, useEffect, useMemo, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

type FieldValue = {
  value: string
  onChange: (value: string) => void
  valueSlider?: number
  onChangeSlider?: (value: number) => void
  state?: TextFieldProps['state']
}

export type FieldConfig = {
  goodPrice: FieldValue
  income: FieldValue
  contribution: FieldValue
  duration: FieldValue
  rate: FieldValue
}

export const PurchaseContainer = ({ ...props }) => {
  const trackAction = useTracking()
  const miniSimulator = useContext(MiniSimulatorContext)
  const debouncedTrackAction = useDebouncedCallback(value => trackAction(value), INPUT_DEBOUNCE_RATE)

  const [durationState, setDurationState] = useState<TextFieldProps['state']>('default')
  const [goodPrice, setGoodPrice] = useState<string>('0')
  const [duration, setDuration] = useState<string>('25')
  const [income, setIncome] = useState<string>('0')
  const [contribution, setContribution] = useState<string>('0')
  const [rate, setRate] = useState<string>(DEFAULT_RATE.toString())
  const [isRateFilled, setIsRateFilled] = useState<boolean>(false)

  const marketRate = getRateFromSimulator({ duration: parseInt(duration), income: parseInt(income), miniSimulator })

  useEffect(() => {
    if (isRateFilled) {
      return
    }

    setRate(roundNumber(marketRate, 0.01).toString())
  }, [marketRate])

  const handleChangeGoodPrice = (value: string) => {
    setGoodPrice(value)
    debouncedTrackAction(TrackingKeys.PurchasePropertyPriceChanged)
  }

  const handleChangeIncome = (value: string) => {
    setIncome(value)
    debouncedTrackAction(TrackingKeys.PurchaseIncomeChanged)
  }

  const handleChangeContribution = (value: string) => {
    setContribution(value)
    debouncedTrackAction(TrackingKeys.PurchaseContributionChanged)
  }

  const handleChangeDuration = (value: string) => {
    setDurationState('default')

    if (!value || parseInt(value) < DURATION_MIN || parseInt(value) > DURATION_MAX) {
      setDurationState('error')
    }

    setDuration(value)
    debouncedTrackAction(TrackingKeys.PurchaseDurationInputChanged)
  }

  const handleChangeDurationSlider = (value: number) => {
    setDurationState('default')
    setDuration(value.toString())
    debouncedTrackAction(TrackingKeys.PurchaseDurationSliderChanged)
  }

  const handleChangeRate = (value: string) => {
    setRate(value)
    debouncedTrackAction(TrackingKeys.PurchaseRateChanged)

    if (!isRateFilled) {
      setIsRateFilled(true)
    }
  }

  const loanAmount = calc.loanAmount({
    goodPrice: parseInt(goodPrice || '0'),
    contribution: parseInt(contribution || '0'),
  })

  const payment =
    durationState !== 'error'
      ? calc.payment({
          loanAmount,
          rate: parseFloat(rate || marketRate.toString() || '0'),
          duration: parseInt(duration || '0'),
        })
      : 0

  const creditCost = calc.creditCost({ payment: roundNumber(payment), duration: parseInt(duration || '0'), loanAmount })

  const handleClickButton = ({ buttonType }: ButtonClickArgs) => {
    trackAction(TrackingKeys.CalculatorCTAClicked, { branch: 'master', buttonType, calculatorType: 'purchase' })
    window.location.href = REDIRECT_PURCHASE
  }

  const field: FieldConfig = {
    income: {
      value: income,
      onChange: handleChangeIncome,
    },
    goodPrice: {
      value: goodPrice,
      onChange: handleChangeGoodPrice,
    },
    contribution: {
      value: contribution,
      onChange: handleChangeContribution,
    },
    duration: {
      value: duration,
      valueSlider: parseInt(duration),
      onChange: handleChangeDuration,
      onChangeSlider: handleChangeDurationSlider,
      state: durationState,
    },
    rate: {
      value: rate,
      onChange: handleChangeRate,
    },
  }

  const purchaseProps = useMemo(
    () => ({
      ...props,
      payment,
      creditCost,
      loanAmount,
      goodPrice: parseInt(goodPrice || '0'),
      duration: parseInt(duration || '0'),
      onClickButton: handleClickButton,
      inputs: getPurchaseInputsProps(field, marketRate),
      isFixedResultsActive: loanAmount > 0,
    }),
    [income, payment, goodPrice, contribution, duration, rate, loanAmount, durationState, props.isActive]
  )

  return <Purchase {...purchaseProps} />
}
