import { roundNumber } from '@pretto/bricks/core/utility/formatters'

import { TextFieldProps } from '@pretto/zen/atoms/textFields/TextField/TextField'

import {
  DURATION_MAX,
  DURATION_MIN,
  INPUT_DEBOUNCE_RATE,
  REDIRECT_CAPACITY,
} from '@pretto/website/src/templates/simulateurPretImmobilier/config/static'
import { TrackingKeys } from '@pretto/website/src/templates/simulateurPretImmobilier/config/tracking'
import { MiniSimulatorContext } from '@pretto/website/src/templates/simulateurPretImmobilier/contexts/MiniSimulatorContext/MiniSimulatorContext'
import * as calc from '@pretto/website/src/templates/simulateurPretImmobilier/lib/calc'
import { getCapacityInputsProps } from '@pretto/website/src/templates/simulateurPretImmobilier/lib/capacityInputProps'
import { getRateFromSimulator } from '@pretto/website/src/templates/simulateurPretImmobilier/lib/helpers'
import { Capacity } from '@pretto/website/src/templates/simulateurPretImmobilier/views/SimulateurPretImmobilier/components/Capacity/Capacity'
import { ButtonClickArgs } from '@pretto/website/src/templates/simulateurPretImmobilier/views/SimulateurPretImmobilier/components/Result/Result'
import { useTracking } from '@pretto/website/src/utilities'

import { useContext, useEffect, useMemo, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

type FieldValue = {
  value: string
  onChange: (value: string) => void
  valueSlider?: number
  onChangeSlider?: (value: number) => void
  state?: TextFieldProps['state']
}

export type FieldConfig = {
  income: FieldValue
  payment: FieldValue
  contribution: FieldValue
  duration: FieldValue
  rate: FieldValue
}

export const CapacityContainer = ({ ...props }) => {
  const trackAction = useTracking()
  const miniSimulator = useContext(MiniSimulatorContext)
  const debouncedTrackAction = useDebouncedCallback(value => trackAction(value), INPUT_DEBOUNCE_RATE)

  const [durationState, setDurationState] = useState<TextFieldProps['state']>('default')
  const [income, setIncome] = useState<string>('0')
  const [payment, setPayment] = useState<string>('0')
  const [contribution, setContribution] = useState<string>('0')
  const [duration, setDuration] = useState<string>('25')
  const [rate, setRate] = useState<string>('0')
  const [isPaymentFilled, setIsPaymentFilled] = useState<boolean>(false)
  const [isRateFilled, setIsRateFilled] = useState<boolean>(false)

  const marketRate = getRateFromSimulator({ duration: parseInt(duration), income: parseInt(income), miniSimulator })
  const computedMarketRate = marketRate.toString()
  const computedPayment = calc.paymentFromIncome(parseInt(income)).toString()

  useEffect(() => {
    if (isPaymentFilled) {
      return
    }

    setPayment(computedPayment)
  }, [income])

  useEffect(() => {
    if (isRateFilled) {
      return
    }

    setRate(computedMarketRate)
  }, [marketRate])

  const handleChangePayment = (value: string) => {
    setPayment(value)
    debouncedTrackAction(TrackingKeys.CapacityPaymentChanged)

    if (!isPaymentFilled) {
      setIsPaymentFilled(true)
    }
  }

  const handleChangeIncome = (value: string) => {
    setIncome(value)
    debouncedTrackAction(TrackingKeys.CapacityIncomeChanged)
  }

  const handleChangeContribution = (value: string) => {
    setContribution(value)
    debouncedTrackAction(TrackingKeys.CapacityContributionChanged)
  }

  const handleChangeDuration = (value: string) => {
    setDurationState('default')

    if (!value || parseInt(value) < DURATION_MIN || parseInt(value) > DURATION_MAX) {
      setDurationState('error')
    }

    setDuration(value)
    debouncedTrackAction(TrackingKeys.CapacityDurationInputChanged)
  }

  const handleChangeDurationSlider = (value: number) => {
    setDurationState('default')
    setDuration(value.toString())
    debouncedTrackAction(TrackingKeys.CapacityDurationSliderChanged)
  }

  const handleChangeRate = (value: string) => {
    setRate(value)
    debouncedTrackAction(TrackingKeys.CapacityRateChanged)

    if (!isRateFilled) {
      setIsRateFilled(true)
    }
  }

  const loanAmount =
    durationState !== 'error'
      ? calc.capacityLoanAmount({
          duration: parseInt(duration || '0'),
          payment: parseInt(payment || computedPayment || '0'),
          rate: parseFloat(rate || computedMarketRate || '0'),
        })
      : 0

  const notaryFees = calc.notaryFees({ loanAmount })
  const guarantyFees = calc.guarantyFees({ loanAmount })
  const goodPrice = calc.goodPrice({
    loanAmount,
    notaryFees,
    guarantyFees,
    contribution: parseInt(contribution || '0'),
  })

  const handleClickButton = ({ buttonType }: ButtonClickArgs) => {
    trackAction(TrackingKeys.CalculatorCTAClicked, { branch: 'master', buttonType, calculatorType: 'capacity' })
    window.location.href = REDIRECT_CAPACITY
  }

  const field: FieldConfig = {
    income: {
      value: income,
      onChange: handleChangeIncome,
    },
    payment: {
      value: payment,
      onChange: handleChangePayment,
    },
    contribution: {
      value: contribution,
      onChange: handleChangeContribution,
    },
    duration: {
      value: duration,
      valueSlider: parseInt(duration) || 0,
      onChange: handleChangeDuration,
      onChangeSlider: handleChangeDurationSlider,
      state: durationState,
    },
    rate: {
      value: rate,
      onChange: handleChangeRate,
    },
  }

  const roundedLoanAmount = roundNumber(loanAmount)

  const capacityProps = useMemo(
    () => ({
      ...props,
      contribution: Number(contribution),
      loanAmount: roundedLoanAmount,
      goodPrice,
      notaryFees,
      guarantyFees,
      onClickButton: handleClickButton,
      inputs: getCapacityInputsProps(field, marketRate),
      isFixedResultsActive: roundedLoanAmount > 0,
    }),
    [income, payment, contribution, duration, rate, loanAmount, durationState, props.isActive]
  )

  return <Capacity {...capacityProps} />
}
