import { formatNumber, roundNumber } from '@pretto/bricks/core/utility/formatters'
import { DURATION_MAX, DURATION_MIN } from '@pretto/website/src/templates/simulateurPretImmobilier/config/static'
import { TextFieldProps } from '@pretto/zen/atoms/textFields/TextField/TextField'
import styled from 'styled-components'

export interface SliderProps {
  value: number | string
  onChange: (value: number) => void
  autoFocus?: boolean
  marks?: []
  max?: number
  min?: number
  onAfterChange?: () => void
  onBeforeChange?: () => void
  railVariant?: string
  step?: number
  tooltipComponent?: JSX.Element
}

type StringOrElement = string | JSX.Element

interface Args {
  marketRate: number
}

type FunctionToString = (obj: Partial<Args>) => StringOrElement | null

enum Slug {
  GoodPrice = 'goodPrice',
  Income = 'income',
  Contribution = 'contribution',
  Duration = 'duration',
  Rate = 'rate',
}

export interface Input {
  slug: Slug
  label: string
  description: StringOrElement | FunctionToString
  textFieldProps: Omit<TextFieldProps, 'value' | 'onChange'>
  isSliderActive?: boolean
  sliderProps?: Omit<SliderProps, 'value' | 'onChange'>
}

export const goodPrice: Input = {
  slug: Slug.GoodPrice,
  label: 'Le bien coûte',
  description: 'Prix de vente du bien',
  textFieldProps: {
    suffix: '€',
    inputProps: { placeholder: '0', type: 'integer' },
  },
}

export const income: Input = {
  slug: Slug.Income,
  label: 'mon foyer gagne',
  description: '€/mois net avant impôts',
  textFieldProps: {
    suffix: '€',
    inputProps: { placeholder: '0', type: 'integer' },
  },
}

export const contribution: Input = {
  slug: Slug.Contribution,
  label: 'en apportant',
  description: '€ d’apport',
  textFieldProps: {
    suffix: '€',
    inputProps: { placeholder: '0', type: 'integer' },
  },
}
export const duration: Input = {
  slug: Slug.Duration,
  label: 'et emprunte sur',
  description: 'Nb d’années d’emprunt',
  textFieldProps: {
    suffix: 'ans',
    inputProps: { placeholder: '0', type: 'integer' },
  },
  sliderProps: {
    min: DURATION_MIN,
    max: DURATION_MAX,
    step: 1,
    railVariant: 'neutral3',
  },
}
export const rate: Input = {
  slug: Slug.Rate,
  label: 'avec un taux de',
  description({ marketRate }) {
    if (marketRate) {
      return (
        <>
          Taux marché : <MarketRate>{formatNumber(roundNumber(marketRate, 0.01), { suffix: '%' })}</MarketRate>
        </>
      )
    }

    return null
  },
  textFieldProps: {
    suffix: '%',
    inputProps: { placeholder: '0.01', type: 'number' },
  },
}

const MarketRate = styled.strong`
  color: ${({ theme }) => theme.colors.primary2};

  && {
    font-weight: 700;
  }
`
