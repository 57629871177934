// stolen from https://usehooks-ts.com/react-hook/use-intersection-observer
import { NAVBAR_HEIGHT } from '@pretto/website/src/components/Navbar/config/navbarHeight'

import { RefObject, useEffect, useState } from 'react'

interface Args extends IntersectionObserverInit {
  freezeOnceVisible?: boolean
}

export const useIntersectionObserver = (
  elementRef: RefObject<Element>,
  // Negative header height to simulate correct viewport
  { threshold = 0, root = null, rootMargin = `-${NAVBAR_HEIGHT}px 0px 0px 0px`, freezeOnceVisible = false }: Args
): IntersectionObserverEntry | undefined => {
  const [entry, setEntry] = useState<IntersectionObserverEntry>()

  const frozen = entry?.isIntersecting && freezeOnceVisible

  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
    setEntry(entry)
  }

  useEffect(() => {
    const node = elementRef?.current
    const hasIOSupport = !!window.IntersectionObserver

    if (!hasIOSupport || frozen || !node) return

    const observerParams = { threshold, root, rootMargin }
    const observer = new IntersectionObserver(updateEntry, observerParams)

    observer.observe(node)

    return () => observer.disconnect()
  }, [elementRef?.current, JSON.stringify(threshold), root, rootMargin, frozen])

  return entry
}
