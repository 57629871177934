import ButtonInline from '@pretto/bricks/components/buttons/ButtonInline'
import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'
import { formatNumber, roundNumber } from '@pretto/bricks/core/utility/formatters'
import Image from '@pretto/bricks/website/shared/components/Image'

import * as P from '@pretto/picto'
import {
  PURCHASE_RESULT_BUTTON_LABEL,
  PURCHASE_RESULT_TITLE,
} from '@pretto/website/src/templates/simulateurPretImmobilier/config/labels'
import { REDIRECT_PURCHASE } from '@pretto/website/src/templates/simulateurPretImmobilier/config/static'
import {
  monthlyInstalmentNumber,
  notaryAndGuarantyFees,
} from '@pretto/website/src/templates/simulateurPretImmobilier/lib/calc'
import { usePosition } from '@pretto/website/src/templates/simulateurPretImmobilier/lib/usePosition'
import {
  Field,
  FieldProps,
} from '@pretto/website/src/templates/simulateurPretImmobilier/views/SimulateurPretImmobilier/components/Field/Field'
import { Fields } from '@pretto/website/src/templates/simulateurPretImmobilier/views/SimulateurPretImmobilier/components/Fields/Fields'
import {
  ButtonClick,
  Result,
} from '@pretto/website/src/templates/simulateurPretImmobilier/views/SimulateurPretImmobilier/components/Result/Result'
import { ResultCondensed } from '@pretto/website/src/templates/simulateurPretImmobilier/views/SimulateurPretImmobilier/components/ResultCondensed/ResultCondensed'

import styled, { css } from 'styled-components'

interface PurchaseProps {
  payment: number
  creditCost: number
  loanAmount: number
  goodPrice: number
  duration: number
  inputs: FieldProps[]
  onClickButton: ButtonClick
  isFixedResultsActive: boolean
  isActive?: boolean
}

export const Purchase: React.FC<PurchaseProps> = ({
  inputs,
  payment,
  creditCost,
  loanAmount,
  goodPrice,
  duration,
  onClickButton,
  isFixedResultsActive,
  isActive = false,
  ...props
}) => {
  const { resultRef, isFixedResultVisible } = usePosition(isActive)

  return (
    <Container {...props} $isActive={isActive}>
      <Fields>
        {inputs.map((fieldProps, index) => (
          <FieldComponent key={index} {...fieldProps} />
        ))}

        <FieldsCta isSecondary onClick={onClickButton.bind(null, { buttonType: 'profile' })}>
          Je complète mon profil
        </FieldsCta>
      </Fields>

      <Result
        title={PURCHASE_RESULT_TITLE}
        buttonLabel={PURCHASE_RESULT_BUTTON_LABEL}
        ref={resultRef}
        result={roundNumber(payment)}
        onClickButton={onClickButton}
        redirect={REDIRECT_PURCHASE}
      >
        <Illustration />

        <Row>
          <RowLabel>
            <Sign as={P.TimesBold} />
            nb de mensualités :
          </RowLabel>
          <RowValue>{monthlyInstalmentNumber({ duration })}</RowValue>
        </Row>

        <Row>
          <RowLabel>
            <Sign as={P.MinusBold} />
            montant à emprunter :
          </RowLabel>
          <RowValue>{formatNumber(roundNumber(loanAmount), { suffix: '€' })}</RowValue>
        </Row>

        <Row>
          <RowLabel>(dont frais annexes</RowLabel>
          <RowValue>{formatNumber(roundNumber(notaryAndGuarantyFees({ goodPrice })), { suffix: '€' })})</RowValue>
        </Row>

        <Divider />

        <RowHighlight>
          <RowLabelHighlight>Coût total du crédit :</RowLabelHighlight>
          <RowValueHighlight>{formatNumber(roundNumber(creditCost), { suffix: '€' })}</RowValueHighlight>
        </RowHighlight>
      </Result>

      <div>
        <ResultCondensed
          title={PURCHASE_RESULT_TITLE}
          result={roundNumber(payment)}
          onClickButton={onClickButton}
          isActive={isFixedResultsActive && isFixedResultVisible}
        />
      </div>
    </Container>
  )
}

const Container = styled.div<{ $isActive: PurchaseProps['isActive'] }>`
  display: none;

  ${({ $isActive }) =>
    $isActive &&
    css`
      display: block;

      @media screen and (min-width: ${breakpoints.laptop}) {
        display: flex;
        align-items: flex-start;
        background-color: ${({ theme }) => theme.colors.neutral5};
        border-radius: ${g(1)};
        padding: ${g(4)} 0 ${g(6)} ${g(5)};
      }
    `}
`
const FieldComponent = styled(Field)`
  & + & {
    margin-top: ${g(2)};
  }
  & + & + & {
    margin-top: ${g(3)};
  }
`
const FieldsCta = styled(ButtonInline)`
  margin-top: ${g(5)};
`
const Illustration = styled(Image).attrs({
  path: 'v1640184459/zen/static-assets/illustrations/660x660/calendar-coin.svg',
  options: { width: ng(10), height: ng(10) },
})`
  display: none;
  width: ${g(10)};
  height: ${g(10)};
  margin: 0 auto;

  @media screen and (min-width: ${breakpoints.laptop}) {
    display: block;
  }
`
const Row = styled.div`
  ${typo.caption12};
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${typo.bodyBook16};
  }
`
const RowLabel = styled.div`
  display: flex;
  align-items: center;
`
const Sign = styled.div`
  margin-right: ${g(1)};
`
const RowValue = styled.div``
const RowHighlight = styled(Row)`
  margin-top: ${g(1)};
`
const RowLabelHighlight = styled(RowLabel)`
  ${typo.bodyBold16};
  color: ${({ theme }) => theme.colors.primary4};
`
const RowValueHighlight = styled(RowValue)`
  ${typo.numbersBold16};
  color: ${({ theme }) => theme.colors.primary3};
`
const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.white};
  margin-top: ${g(1)};
`
