import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import * as typo from '@pretto/bricks/core/typography'

import { PostContentContext } from '@pretto/website/src/postTypes/context/PostContent'
import { CapacityContainer } from '@pretto/website/src/templates/simulateurPretImmobilier/containers/capacity'
import { PurchaseContainer } from '@pretto/website/src/templates/simulateurPretImmobilier/containers/purchase'
import {
  SimulatorCardProps,
  SimulatorsList,
} from '@pretto/website/src/templates/simulateurPretImmobilier/views/SimulateurPretImmobilier/components/SimulatorsList/SimulatorsList'

import { useContext } from 'react'
import styled, { css } from 'styled-components'

export interface SimulateurPretImmobilierProps {
  activeType: 'capacity' | 'purchase'
  onShowCapacity: () => void
  onShowPurchase: () => void
  simulatorCards: SimulatorCardProps[]
}

export const SimulateurPretImmobilier: React.FC<SimulateurPretImmobilierProps> = ({
  activeType,
  onShowCapacity,
  onShowPurchase,
  simulatorCards,
}) => {
  const { contentWithSidebarComponent } = useContext(PostContentContext)
  const isCapacityActive = activeType === 'capacity'
  const isPurchaseActive = activeType === 'purchase'

  return (
    <>
      <Page>
        <Wrapper>
          <Header>
            <Title>Simulez votre prêt immobilier</Title>

            <Tabs>
              <Tab $isActive={isCapacityActive} onClick={onShowCapacity}>
                Estimez votre capacité d’emprunt
              </Tab>
              <Tab $isActive={isPurchaseActive} onClick={onShowPurchase}>
                Estimez votre mensualité
              </Tab>
            </Tabs>
          </Header>

          <Main>
            <MainContainer>
              <CapacityContainer isActive={isCapacityActive} />
              <PurchaseContainer isActive={isPurchaseActive} />
            </MainContainer>
          </Main>
        </Wrapper>
      </Page>

      <SimulatorsList simulatorCards={simulatorCards} />

      <ContentWrapper>
        <Content>{contentWithSidebarComponent}</Content>
      </ContentWrapper>
    </>
  )
}

const Page = styled.div`
  margin-bottom: ${g(4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(9)};
  }
`
const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
`
const Tabs = styled.div`
  ${column([1, -1])};
  margin-top: ${g(1)};
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral3};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, -2])};
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: ${g(3)};
    justify-items: center;
    margin-bottom: ${g(3)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, -3])};
    display: flex;
    margin-bottom: ${g(4)};
    padding-left: ${g(3)};
    grid-gap: ${g(9)};
  }
`
const Tab = styled.div<{ $isActive: boolean }>`
  ${typo.link12};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral2};
  flex: 1 1 50%;
  padding: ${g(2.5)} ${g(2)};
  border-bottom: 2px solid transparent;
  transform: translateY(1px);
  cursor: pointer;

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${({ theme }) => theme.colors.neutral1};
      border-bottom-color: ${({ theme }) => theme.colors.primary2};
    `}

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${typo.headline16};
    grid-column-end: span 4;
    padding-left: 0;
    padding-right: 0;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    flex: 0 0 auto;
  }
`
const Title = styled.h1`
  ${column([2, -2])};
  ${typo.heading32};
  text-align: center;

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, -3])};
    ${typo.heading48};
    text-align: left;
  }
`
const Header = styled.div`
  ${grid()};
`
const Main = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral4};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${grid()};
    margin-bottom: ${g(8)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    background-color: ${({ theme }) => theme.colors.white};
  }
`
const MainContainer = styled.div`
  ${column([2, -2])};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, -3])};
  }
`
const ContentWrapper = styled.section`
  ${grid()};
`
const Content = styled.div`
  ${column([2, 4])};
  margin-top: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, -3])};
  }
`
