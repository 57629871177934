import { NAVBAR_HEIGHT } from '@pretto/website/src/components/Navbar/config/navbarHeight'
import { useIntersectionObserver } from '@pretto/website/src/utilities/useIntersectionObserver'

import { useEffect, useRef, useState } from 'react'

enum ArrowDirection {
  Top = 'top',
  Bottom = 'bottom',
}
export const usePosition = (isVisible: boolean) => {
  const resultRef = useRef<HTMLDivElement | null>(null)
  const entry = useIntersectionObserver(resultRef, {})
  const isIntersecting = !!entry?.isIntersecting
  const [isAbove, setIsAbove] = useState(false)
  const [isBelow, setIsBelow] = useState(false)
  const [arrowDirection, setArrowDirection] = useState<ArrowDirection>(ArrowDirection.Bottom)

  useEffect(() => {
    const rect = entry?.boundingClientRect

    if (rect) {
      const { bottom, top } = rect
      const isAbove = bottom - NAVBAR_HEIGHT <= 0
      const isBelow = top - (window.innerHeight || document.documentElement.clientHeight) >= 0

      setIsAbove(isAbove)
      setIsBelow(isBelow)

      if (isAbove) {
        setArrowDirection(ArrowDirection.Top)
        return
      }

      if (isBelow) {
        setArrowDirection(ArrowDirection.Bottom)
      }
    }
  }, [isVisible, isIntersecting])

  const handleScrollResult = () => {
    const top = resultRef.current?.offsetTop ?? 0
    window.scrollTo({ top, behavior: 'smooth' })
  }

  const isFixedResultVisible = isAbove || isBelow

  return {
    resultRef,
    isAbove,
    isBelow,
    isFixedResultVisible,
    arrowDirection,
    onScrollResult: handleScrollResult,
  }
}
