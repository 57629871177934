import { roundNumber } from '@pretto/bricks/core/utility/formatters'

import {
  GUARANTY_FEES_RATE,
  INDEBTEDNESS_RATE,
  NOTARY_FEES_RATE,
} from '@pretto/website/src/templates/simulateurPretImmobilier/config/static'

interface CalcVariables {
  contribution: number
  duration: number
  goodPrice: number
  income: number
  loanAmount: number
  notaryFees: number
  guarantyFees: number
  payment: number
  rate: number
}

export const paymentFromIncome = (income: CalcVariables['income']) => roundNumber(income * INDEBTEDNESS_RATE)

export const monthlyRate = (rate: CalcVariables['rate']) => rate / (12 * 100)

export const loanAmount = ({ goodPrice, contribution = 0 }: Pick<CalcVariables, 'goodPrice' | 'contribution'>) => {
  if (!goodPrice) return 0

  return Math.max(
    goodPrice - contribution + notaryFees({ loanAmount: goodPrice }) + guarantyFees({ loanAmount: goodPrice }),
    0
  )
}

export const payment = ({
  loanAmount,
  rate: annualRate,
  duration,
}: Pick<CalcVariables, 'loanAmount' | 'rate' | 'duration'>) => {
  if (!loanAmount || !duration) {
    return 0
  }
  const rate = monthlyRate(annualRate)

  return Math.max(loanAmount * (rate / (1 - (1 + rate) ** (-duration * 12))), 0)
}

export const creditCost = ({
  payment,
  duration,
  loanAmount,
}: Pick<CalcVariables, 'payment' | 'duration' | 'loanAmount'>) => {
  if (!duration || !payment || !loanAmount) return 0

  return Math.max(payment * duration * 12 - loanAmount)
}

export const goodPrice = ({
  loanAmount,
  notaryFees = 0,
  guarantyFees = 0,
  contribution = 0,
}: Pick<CalcVariables, 'loanAmount' | 'notaryFees' | 'guarantyFees' | 'contribution'>) => {
  if (!loanAmount) return 0

  return Math.max(loanAmount - notaryFees - guarantyFees + contribution)
}

export const notaryFees = ({ loanAmount }: Pick<CalcVariables, 'loanAmount'>) => {
  if (!loanAmount) return 0

  return loanAmount * NOTARY_FEES_RATE
}

export const guarantyFees = ({ loanAmount }: Pick<CalcVariables, 'loanAmount'>) => {
  if (!loanAmount) return 0

  return loanAmount * GUARANTY_FEES_RATE
}

export const notaryAndGuarantyFees = ({ goodPrice }: Pick<CalcVariables, 'goodPrice'>): number =>
  notaryFees({ loanAmount: goodPrice }) + guarantyFees({ loanAmount: goodPrice })

export const capacityLoanAmount = ({
  duration,
  payment,
  rate: annualRate,
}: Pick<CalcVariables, 'payment' | 'rate' | 'duration'>) => {
  if (annualRate === 0) {
    return payment * duration * 12
  }

  const rate = monthlyRate(annualRate)

  return Math.max(payment / (rate / (1 - (1 + rate) ** (-duration * 12))))
}

export const monthlyInstalmentNumber = ({ duration }: Pick<CalcVariables, 'duration'>): number => duration * 12
