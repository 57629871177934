import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'

import styled from 'styled-components'

export const Fields: React.FC = ({ children }) => (
  <Main>
    <Content>{children}</Content>
  </Main>
)

const Main = styled.div`
  ${grid()};
  padding: ${g(3)} 0 ${g(5)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: block;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding: 0;
    flex: 1 1 auto;
  }
`
const Content = styled.div`
  ${column([2, -2])};
  text-align: initial;

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding-top: ${g(2)};
  }
`
