import { g } from '@pretto/bricks/components/layout'
import { caption12 } from '@pretto/bricks/core/typography'

import { CheckHandwrite } from '@pretto/picto'
import { SIMULATION_DURATION } from '@pretto/website/src/config/reassurance'

import styled from 'styled-components'

const DEFAULT_TICKS = ['Gratuit', `${SIMULATION_DURATION.toString()} min`, 'Négocié par un expert']

interface TicksProps {
  ticks?: string[]
}

export const Ticks: React.FC<TicksProps> = ({ ticks, ...props }) => {
  const ticksToDisplay = ticks || DEFAULT_TICKS

  return (
    <List {...props}>
      {ticksToDisplay.map(item => (
        <Item key={item}>
          <CheckHandwrite />
          <Text>{item}</Text>
        </Item>
      ))}
    </List>
  )
}

const List = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
`
const Item = styled.li`
  display: flex;
  align-items: center;

  & + & {
    margin-left: ${g(2)};
  }
`
const Text = styled.span`
  ${caption12};
`
