import { roundNumber } from '@pretto/bricks/core/utility/formatters'

import {
  DEFAULT_INCOME,
  DURATION_MAX,
  DURATION_MIN,
} from '@pretto/website/src/templates/simulateurPretImmobilier/config/static'

export type MiniSimulatorResult = {
  salary_min: number
  salary_max: number
  duration: number
  rate_market: number
}
export interface MiniSimulator {
  results: MiniSimulatorResult[]
}

export const getMaxSalary = (miniSimulator: MiniSimulator): number =>
  miniSimulator.results.reduce((previous, { salary_max }) => {
    if (salary_max > previous) return salary_max
    return previous
  }, 0)

interface Match {
  duration: number
  income: number
  miniSimulator: MiniSimulator | null
}

export const getRateFromSimulator = ({ duration, income, miniSimulator }: Match): number => {
  if (!miniSimulator) return 0

  const maxSalary = getMaxSalary(miniSimulator)
  const computedIncome = !income ? DEFAULT_INCOME : income > maxSalary ? maxSalary : income
  const computedDuration = !duration
    ? DURATION_MAX
    : duration < DURATION_MIN
    ? DURATION_MIN
    : duration > DURATION_MAX
    ? DURATION_MAX
    : Math.round(duration)

  const match = miniSimulator.results.find(data => {
    return data.duration === computedDuration && computedIncome >= data.salary_min && computedIncome <= data.salary_max
  })

  return roundNumber(match?.rate_market ?? 0, 0.01)
}
