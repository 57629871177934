export const useFrontChat = () => {
  const frontChatBubbleElm = typeof window !== 'undefined' && document.getElementById('front-chat-container')

  const hide = () => {
    if (frontChatBubbleElm) {
      frontChatBubbleElm.style.display = 'none'
    }
  }

  const show = () => {
    if (frontChatBubbleElm) {
      frontChatBubbleElm.style.display = 'block'
    }
  }

  return { show, hide }
}
