import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import * as typo from '@pretto/bricks/core/typography'
import SimulatorCard from '@pretto/bricks/website/calculator2/components/SimulatorCard'

import styled from 'styled-components'

export interface SimulatorCardProps {
  content: string
  cta: string
  href: string
  illustrationSlug: string
  title: string
}
export interface SimulatorsListProps {
  simulatorCards: SimulatorCardProps[]
}

export const SimulatorsList: React.FC<SimulatorsListProps> = ({ simulatorCards }) => (
  <Bloc>
    <Wrapper>
      <Section>
        <Title>Réussissez votre prêt immobilier grâce à nos autres simulateurs !</Title>

        <Cards>
          {simulatorCards.map((cardProps, i) => (
            <SimulatorCard key={i} {...cardProps} />
          ))}
        </Cards>
      </Section>
    </Wrapper>
  </Bloc>
)

const Bloc = styled.div`
  ${grid()}
  background-color: ${({ theme }) => theme.colors.primary2};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${g(1)};
    margin-bottom: ${g(1)};
  }
`
const Wrapper = styled.div`
  ${column([1, -1])};

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${column([3, -3])};
  }
`
const Section = styled.div`
  position: relative;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    width: ${g(3)};
    opacity: 0.3;
    content: '';
  }

  &::before {
    left: 0;
    background: linear-gradient(90deg, ${({ theme }) => theme.colors.primary2}, transparent);
  }

  &::after {
    right: 0;
    background: linear-gradient(-90deg, ${({ theme }) => theme.colors.primary2}, transparent);
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    &::before,
    &::after {
      display: none;
    }
  }
`
const Title = styled.div`
  ${typo.headline16};
  color: ${({ theme }) => theme.colors.white};
  padding: ${g(3)} ${g(3)} 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(5)} calc(100% / 8 + ${g(3)}) 0;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding-left: calc(100% / 12 + ${g(3)});
    padding-right: calc(100% / 12 + ${g(3)});
  }
`
const Cards = styled.ul`
  margin-top: ${g(4)};
  display: flex;
  overflow: auto;
  padding: 0 ${g(3)} ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-left: calc(100% / 8 + ${g(3)});
    padding-right: calc(100% / 8 + ${g(3)});
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding-left: calc(100% / 12 + ${g(3)});
    padding-right: calc(100% / 12 + ${g(3)});
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-left: 0;
    padding-right: 0;
  }
`
