import { g } from '@pretto/bricks/components/layout'

import { ChevronRightBold } from '@pretto/picto'
import { ButtonBase } from '@pretto/website/src/templates/simulateurPretImmobilier/views/SimulateurPretImmobilier/components/ButtonBase/ButtonBase'

import styled from 'styled-components'

interface ButtonProps {
  children: React.ReactNode
  onClick: () => void
  role?: string
  href?: string
  label?: string
}

export const Button: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <ButtonBase {...props}>
      <ButtonComponent>
        <Content>{children}</Content>
        <ChevronRightBold />
      </ButtonComponent>
    </ButtonBase>
  )
}

const ButtonComponent = styled.div`
  padding: ${g(2)} ${g(3)};
  display: flex;
  justify-content: center;
  align-items: center;
`

const Content = styled.div`
  margin-right: ${g(2)};
`
