import { breakpoints, g } from '@pretto/bricks/components/layout'
import Slider from '@pretto/bricks/components/utility/Slider'
import { caption12, heading16 } from '@pretto/bricks/core/typography'
import { SliderProps } from '@pretto/website/src/templates/simulateurPretImmobilier/config/capacityInputs'
import { TextField, TextFieldProps } from '@pretto/zen/atoms/textFields/TextField/TextField'
import styled, { css } from 'styled-components'

export interface FieldProps {
  label: string
  description: React.ReactNode
  textFieldProps: TextFieldProps
  isMain?: boolean
  sliderProps?: SliderProps
}

const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
  const value = e.target.value

  if (value === '0') {
    e.target.setSelectionRange(0, 1)
  }
}

export const Field: React.FC<FieldProps> = ({
  label,
  description,
  textFieldProps,
  isMain = false,
  sliderProps,
  ...props
}) => {
  const isSliderActive = !!sliderProps

  return (
    <Card {...props} $isMain={isMain}>
      <Label>{label}</Label>

      <Description $isMain={isMain}>{description}</Description>

      <TextFieldComponent
        {...textFieldProps}
        theme="color"
        inputProps={{ ...textFieldProps.inputProps, onFocus: handleFocus }}
      />

      {isSliderActive && <SliderComponent {...sliderProps} />}
    </Card>
  )
}

interface WithMainProps {
  $isMain: FieldProps['isMain']
}

const Card = styled.div<WithMainProps>`
  display: grid;
  grid-template-columns: 1fr ${g(15)};
  grid-template-rows: 1fr 1fr auto;
  grid-column-gap: ${g(1)};

  ${({ $isMain }) =>
    $isMain &&
    css`
      background-color: ${({ theme }) => theme.colors.primary2};
      color: ${({ theme }) => theme.colors.white};
      border-radius: ${g(1)};
      padding: ${g(2)};
    `}

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-template-columns: 1fr ${g(24)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    grid-template-columns: 1fr ${g(20)};
  }
`
const Label = styled.div`
  ${heading16};
`
const Description = styled.div<WithMainProps>`
  ${caption12};
  color: ${({
    theme: {
      colors: { white, overlay },
    },
    $isMain,
  }) => ($isMain ? white : overlay)};
  grid-column-start: 1;
`
const TextFieldComponent = styled(TextField)`
  grid-column-start: 2;
  grid-row: 1 / -1;
`
const SliderComponent = styled(Slider)`
  grid-column: 1 / -1;
  margin-top: ${g(1)};
`
