import { breakpoints, g } from '@pretto/bricks/components/layout'
import { headline16, numbers32 } from '@pretto/bricks/core/typography'
import { formatNumber } from '@pretto/bricks/core/utility/formatters'

import { ChevronRightBold } from '@pretto/picto'
import { ButtonBase } from '@pretto/website/src/templates/simulateurPretImmobilier/views/SimulateurPretImmobilier/components/ButtonBase/ButtonBase'
import { ButtonClick } from '@pretto/website/src/templates/simulateurPretImmobilier/views/SimulateurPretImmobilier/components/Result/Result'
import { useFrontChat } from '@pretto/website/src/utilities/useFrontChat'

import { useEffect } from 'react'
import styled, { css } from 'styled-components'
import useDetectKeyboardOpen from 'use-detect-keyboard-open'

interface ResultCondensedProps {
  isActive?: boolean
  title: string
  result: number
  onClickButton: ButtonClick
}

export const ResultCondensed: React.FC<ResultCondensedProps> = ({
  isActive = false,
  title,
  result,
  onClickButton,
  ...props
}) => {
  const isKeyboardOpen = useDetectKeyboardOpen()
  const { show, hide } = useFrontChat()

  useEffect(() => {
    if (isActive) {
      hide()
      return
    }

    show()

    return () => {
      show()
    }
  }, [isActive])

  return (
    <Main {...props} $isActive={isActive && !isKeyboardOpen}>
      <Title>{title}</Title>
      <Highlight>{formatNumber(result, { suffix: '€' })}</Highlight>

      <Button onClick={onClickButton.bind(null, { buttonType: 'sticky' })}>
        <Picto />
      </Button>
    </Main>
  )
}

const Main = styled.div<{ $isActive: boolean }>`
  background-color: ${({ theme }) => theme.colors.primary2};
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  position: relative;
  transform: translateY(100%);
  transition: all 250ms ease-in-out;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
  box-shadow: 0px -1px 0px rgba(23, 55, 83, 0.2);
  padding: ${g(2)} 0 ${g(3)};

  ${({ $isActive }) =>
    $isActive &&
    css`
      transform: translateY(0);
    `}

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`
const Title = styled.h2`
  ${headline16};
`
const Highlight = styled.div`
  ${numbers32};
`
const Button = styled(ButtonBase)`
  width: ${g(5)};
  height: ${g(6, -2)};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: ${g(3)};
  right: ${g(2.5)};
`
const Picto = styled(ChevronRightBold)`
  transform: translateY(3px);
`
