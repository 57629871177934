import { MINI_SIMULATOR_MARKET_API_KEY } from '@pretto/website/config'
import { MiniSimulatorContext } from '@pretto/website/src/templates/simulateurPretImmobilier/contexts/MiniSimulatorContext/MiniSimulatorContext'
import type { MiniSimulator } from '@pretto/website/src/templates/simulateurPretImmobilier/lib/helpers'
import { SimulateurPretImmobilier as SimulateurPretImmobilierView } from '@pretto/website/src/templates/simulateurPretImmobilier/views/SimulateurPretImmobilier/SimulateurPretImmobilier'
import { useTracking } from '@pretto/website/src/utilities/tracking'

import { graphql } from 'gatsby'
import { useEffect, useMemo, useState } from 'react'

import { TrackingKeys } from '../config/tracking'

interface SimulateurPretImmobilierProps {
  data: {
    template: {
      simulateurPretImmobilier: {
        simulators: {
          content: string
          cta: string
          illustrationSlug: string
          title: string
          relatedPost: { uri: string }
        }[]
      }
    }
    title: string
  }
  contentComponent: React.Component
}

export const SimulateurPretImmobilier = ({ data, ...props }: SimulateurPretImmobilierProps) => {
  const {
    template: {
      simulateurPretImmobilier: { simulators },
    },
  } = data

  const simulatorCards = simulators.map(({ relatedPost, ...simulator }) => ({
    ...simulator,
    href: relatedPost.uri,
  }))

  const trackAction = useTracking()

  const [type, setType] = useState<'capacity' | 'purchase'>('capacity')
  const [miniSimulator, setMiniSimulator] = useState<MiniSimulator | null>(null)

  useEffect(() => {
    const fetchPromise = fetch('https://europe-west1-pretto-apis.cloudfunctions.net/mini-simulator-market', {
      headers: {
        Authorization: `ApiKey ${MINI_SIMULATOR_MARKET_API_KEY}`,
        'Content-Type': 'application/json',
      },
    })

    fetchPromise
      .then(response => response.json())
      .then(data => {
        setMiniSimulator(data)
      })
  }, [])

  const handleShowCapacity = () => {
    setType('capacity')
    trackAction(TrackingKeys.CapacityClicked)
  }
  const handleShowPurchase = () => {
    setType('purchase')
    trackAction(TrackingKeys.PurchaseClicked)
  }

  const viewProps = {
    ...props,
    activeType: type,
    onShowCapacity: handleShowCapacity,
    onShowPurchase: handleShowPurchase,
    simulatorCards,
  }

  const pageComponent = useMemo(() => <SimulateurPretImmobilierView {...viewProps} />, [viewProps])

  return <MiniSimulatorContext.Provider value={miniSimulator}>{pageComponent}</MiniSimulatorContext.Provider>
}

export const fragment = graphql`
  fragment SimulateurPretImmobilierTemplate on WpTemplate_SimulateurPrtImmobilier {
    simulateurPretImmobilier {
      simulators {
        content
        cta
        illustrationSlug: illustrationslug
        relatedPost: relatedpost {
          ... on WpPost {
            uri
          }
        }
        title
      }
    }
  }
`
